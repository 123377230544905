import { Space, Input, Modal, Table } from "antd";
import React, { useEffect, useState, FC } from "react";
import { CoreControllerComponentTest } from "../CoreControllerComponent/CoreControllerComponentTest";
import { Col, Row } from "react-bootstrap";
import { Badge } from "primereact/badge";
import { ButtonComponents } from "../../ButtonComponents/ButtonComponents";
import { InputTextComponents } from "../../InputTextComponents/InputTextComponents";
import { SelectDataDialog } from "../../Select/SelectionDataDialog/SelectDataDialog";
import { AutoComplete } from "primereact/autocomplete";
import { formatDateTime } from "../../../Helper/FormatDateTime";
import { useUserContext } from "../../../Context/UserContext";
import { Dropdown } from "primereact/dropdown";
import LeaveTypeComponents from "./LeaveTypeComponents/LeaveTypeComponents";
import TinyMceComponent from "../../TinyMceComponent/TinyMceComponent";
import TableReferenceComponent from "./RefTemplateComponent/TableRefTemplateComponent";
import DialogRefTemplateComponent from "./RefTemplateComponent/DialogRefTemplateComponent";
import TemplateVersionComponent from "./TemplateVersionComponent/TemplateVersionComponent";
import { INumberFormula } from "../../../IRequestModel/INumberFormula";
import { IAutoNumberAttibute } from "../../../IRequestModel/IAutoNumberFormat";
import { IRvsAttribute } from "../../../IRequestModel/IRvision";
import { ILogic } from "../../../IRequestModel/ILogicModel";
import { tableSelectOption } from "../../AntdControlComponent/TableComponent/TableComponent";
import { IRequestOnSubmit } from "../../../IRequestModel/CreateFormControl/IRequestOnSubmit";
import { GetAllDynamic } from "../../../Services/DynamicService";
import { Button } from "primereact/button";
import { TextHeaderComponents } from "../../TextHeaderComponents/TextHeaderComponents";
import { GetAllEmployee } from "../../../Services/EmployeeService";
import {
  GetAllMasterData,
  GetMasteLeave,
} from "../../../Services/MasterDataService";
import { ColumnsType } from "antd/lib/table";
import useMasterSetting from "../../../hooks/useMasterSetting";
import { UseFormGetValues, UseFormTrigger } from "react-hook-form";

type Props = {
  t: any;
  memoDetail: any;
  setMemoDetail: any;
  masterCompanies: any;
  masterEmployee: any;
  canEditDoc: boolean;
  control: any;
  register: any;
  handleSubmit: any;
  onSubmit: any;
  onSubmitInterface: (
    formData: any,
    data: IRequestOnSubmit,
    setLoadingInterface: any
  ) => void;
  setListInToAndPass: any;
  listInToAndPass: any;
  masterProjects: any;
  project: any;
  setProject: any;
  finFo: any;
  masterInterface: any;
  isBranchFromADTitle: boolean;
  checkedLeaveTemplate: boolean | undefined;
  leaveTypeTable: any;
  errors: any;
  isTextFromValue: boolean;
  setTextFromValue: any;
  textFromValue: any;
  refTempSelected: any;
  setRefTempSelected: any;
  setSearchRefDocData: any;
  searchRefDocData: any;
  refAttribute: any;
  refOptions: any[];
  templateListVersion: any;
  selectedTemplateVersion: any;
  setSelectedTemplateVersion: any;
  isTemplateVersion: boolean;
  dialogVisibleInRefTemplate: boolean;
  setDialogVisibleInRefTemplate: (value: boolean) => void;
  allLogic: any;
  reset: any;
  onControlChange: any;
  jsonConditions: any;
  setJsonConditions: any;
  numFormulas: INumberFormula[];
  lineApproval: any;
  setLineApproval: (value: any[]) => void;
  autoNumFormat: IAutoNumberAttibute;
  setValue: any;
  checkActionPage: string;
  buttonType: string;
  isControlLoading: boolean;
  setIsControlLoading: (valaue: boolean) => void;
  getLineApproveForAmount: (
    amount: number,
    jsonCondition?: string,
    logicType?: string,
    layout?: any
  ) => Promise<void>;
  previousView: any;
  tableSummaries: any;
  setTableSummaries: (value: any[]) => void;
  isFirstRun: any;
  listFormNames: any;
  setListRefDocDetails: (value: any[]) => void;
  isShowSum: boolean;
  canEditRefDoc: boolean;
  refLoading: boolean;
  previousRefTempSelected: any;
  setListFileAttachDetails: (value: any[]) => void;
  prepareInitialLogic: (
    logics: ILogic[],
    templateDesc: any,
    memoDetail: any
  ) => void;
  onProcessLogicReference: (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    control: any
  ) => Promise<{
    loadTo: any[];
    data: any[];
    row: number;
    col: number;
  } | null>;
  tableOptions: tableSelectOption[];
  setTableOptions: (value: tableSelectOption[]) => void;
  returnMessage: string;
  listReturn: any;
  checkInterFace: any;
  interfaceButton: any;
  actionAlert?: any;
  setButtonType: (data: any) => void;
  onFocusProps?: () => void;
  onBlurProps?: () => void;
  setEmployeeList: any;
  employeeList: any;
  edFont?: any;
  defaultFont?: any;
  setDefaultTemplateDesc: any;
  trigger: UseFormTrigger<{
    items: any;
  }>;
  getValues: UseFormGetValues<{
    items: any;
  }>;
};

const InformationComponentFix: FC<Props> = ({
  t,
  onProcessLogicReference,
  isFirstRun,
  memoDetail,
  setMemoDetail,
  canEditDoc,
  masterCompanies,
  masterEmployee,
  control,
  register,
  trigger,
  getValues,
  previousRefTempSelected,
  isShowSum,
  refOptions,
  canEditRefDoc,
  handleSubmit,
  setListFileAttachDetails,
  onSubmit,
  onSubmitInterface,
  setListInToAndPass,
  listInToAndPass,
  masterProjects,
  setProject,
  project,
  finFo,
  masterInterface,
  isBranchFromADTitle,
  checkedLeaveTemplate,
  leaveTypeTable,
  errors,
  isTextFromValue,
  setTextFromValue,
  textFromValue,
  refTempSelected,
  setRefTempSelected,
  setSearchRefDocData,
  searchRefDocData,
  refAttribute,
  templateListVersion,
  setSelectedTemplateVersion,
  selectedTemplateVersion,
  isTemplateVersion,
  dialogVisibleInRefTemplate,
  setDialogVisibleInRefTemplate,
  allLogic,
  reset,
  onControlChange,
  jsonConditions,
  setJsonConditions,
  numFormulas,
  setLineApproval,
  autoNumFormat,
  lineApproval,
  setValue,
  checkActionPage,
  buttonType,
  isControlLoading,
  setIsControlLoading,
  getLineApproveForAmount,
  previousView,
  tableSummaries,
  setTableSummaries,
  listFormNames,
  setListRefDocDetails,
  refLoading,
  prepareInitialLogic,
  tableOptions,
  setTableOptions,
  returnMessage,
  listReturn,
  checkInterFace,
  interfaceButton,
  actionAlert,
  setButtonType,
  onBlurProps,
  onFocusProps,
  setEmployeeList,
  employeeList,
  edFont,
  defaultFont,
  setDefaultTemplateDesc,
}) => {
  const [dialogVisibleInCompany, setDialogVisibleInCompany] = useState(false);
  const [dialogVisibleInEmployee, setDialogVisibleInEmployee] = useState(false);
  const [userData, setUserData] = useUserContext();
  const [hideProject, setIsHideProject] = useState(false);
  const [hideBranch, setIsHideBranch] = useState(false);
  const [hideTo, setIsHideTo] = useState(false);
  const [hideCc, setIsHideCc] = useState(false);
  const [hideSubject, setIsHideSubject] = useState(false);
  const [typeEmployeeDialog, setTypeEmployeeDialog] = useState<string>("");
  const [filteredEmployee, setFilteredEmployee] = useState<any>([]);
  const [filterCompany, setFilterCompany] = useState<any>();
  const [nameFile, setNameFile] = useState<string>();
  const [linkFile, setLinkFile] = useState<string>();
  const _userData = JSON.parse(window.localStorage.getItem("userData") || "");
  const _sharepointSiteURL = _userData.SharepointSiteURL;
  const [hasUserManual, setHasUserManual] = useState<boolean>();
  const [searchData, setSearchData] = useState<any[]>(masterEmployee);
  const [isMobile, setIsMobile] = useState<any>(false);
  const [toValue, setToValue] = useState<any[]>(listInToAndPass.to);
  const [CCValue, setCCValue] = useState<any[]>(listInToAndPass.pass);
  const { masterSetting } = useMasterSetting();

  useEffect(() => {
    if (listInToAndPass.to) {
      let filtered = listInToAndPass.to.filter(
        (e: any, idx: number) => idx <= 2
      );
      setToValue(filtered);
    }
    if (listInToAndPass.pass) {
      let filtered = listInToAndPass.pass.filter(
        (e: any, idx: number) => idx <= 2
      );
      setCCValue(filtered);
    }
  }, [listInToAndPass.to, listInToAndPass.pass]);

  useEffect(() => {
    fetchMasterData();
    checkHasFifoFunction();
  }, []);

  useEffect(() => {
    checkDevice();
  }, []);

  const checkDevice = () => {
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(navigator.userAgent);
    setIsMobile(isMobileDevice);
    if (isMobileDevice) {
      console.log("Mobile Device");
    } else {
      console.log("Desktop");
    }
  };

  function checkHasFifoFunction() {
    var _finFo = finFo;

    if (_finFo) {
      if (_finFo.Value4?.toLowerCase() === "true") {
        if (_finFo.Value1 && _finFo.Value2) {
          const _display = _finFo.Value1.split("|");

          const _dataReplace = _finFo.Value2.split("|");
          for (let index = 0; index < _display.length; index++) {
            const element = _display[index];
            const element2 = _dataReplace[index];
            var documentElement: any = document.getElementById(
              element
            ) as HTMLDivElement | null;
            if (documentElement) {
              documentElement.innerHTML = element2;
            }
          }
        }
        if (_finFo.Value3 === "true") {
          setIsHideProject(true);
        }
        if (_finFo.Value5 !== "" && _finFo.Value5 !== null) {
          const _showBranch = _finFo.Value5.split("|");

          if (_showBranch.length >= 1) {
            if (_showBranch[0] == "true") {
              setIsHideBranch(true);
            }
          }
          if (_showBranch.length >= 2) {
            if (_showBranch[1] === "true") {
              setIsHideTo(true);
            }
          }
          if (_showBranch.length >= 3) {
            if (_showBranch[2] === "true") {
              setIsHideCc(true);
            }
          }
          if (_showBranch.length >= 4) {
            if (_showBranch[3] === "true") {
              setIsHideSubject(true);
            }
          }
        }
      }
    }
  }

  function showModalCompany() {
    if (filterCompany != "") {
      setFilterCompany("");
    }
    setDialogVisibleInCompany(!dialogVisibleInCompany);
  }

  const onSelectedCompany = async (data: any) => {
    let objeMemo = { ...memoDetail };
    let com_name = data.CompanyCode + " : ";
    if (userData.Lang === "EN") {
      com_name = com_name + data.NameEn;
    } else {
      com_name = com_name + data.NameTh;
    }
    objeMemo.company_id = data.CompanyId;
    objeMemo.company_name = com_name;
    objeMemo.checkChangeComp = true;

    const templateDesc: any = await prepareInitialLogic(
      allLogic,
      getValues(),
      objeMemo
    );
    setMemoDetail(objeMemo);
    setDefaultTemplateDesc(templateDesc.items);
    setDialogVisibleInCompany(false);
  };

  const searchEmployeeToAndPass = (event: any) => {
    setTimeout(async () => {
      let _filteredEmployee;
      if (employeeList.length <= 0) {
        let req = {
          IsActiveOnly: true,
          Scopes: "name,email,org",
        };
        const employee = await GetAllEmployee(req);
        setEmployeeList(employee);
      }
      if (!event.query.trim().length) {
        if (employeeList.length > 0) {
          _filteredEmployee = [...employeeList];
        }
      } else {
        _filteredEmployee = employeeList.filter((_dataEmployee: any) => {
          if (
            _dataEmployee.NameEn.toLowerCase().includes(
              event.query.toLowerCase()
            )
          ) {
            return true;
          } else if (
            _dataEmployee.NameTh.toLowerCase().includes(
              event.query.toLowerCase()
            )
          ) {
            return true;
          } else if (
            _dataEmployee.PositionNameTh.toLowerCase().includes(
              event.query.toLowerCase()
            )
          ) {
            return true;
          } else if (
            _dataEmployee.PositionNameEn.toLowerCase().includes(
              event.query.toLowerCase()
            )
          ) {
            return true;
          }
        });
      }

      setFilteredEmployee(_filteredEmployee);
    }, 250);
  };
  const [showDialogEmp, setShowDialogEmp] = useState(true);
  async function showModalInEmployeeToAndPass(type: string) {
    if (filteredEmployee) {
      setFilteredEmployee("");
    }
    setDialogVisibleInEmployee(!dialogVisibleInEmployee);
    setTypeEmployeeDialog(type);
    let req = {
      IsActiveOnly: true,
      Scopes: "name,email,org",
    };
    if (showDialogEmp) {
      if (employeeList.length <= 0) {
        const employee = await GetAllEmployee(req);
        setEmployeeList(employee);
        setSearchData(employee);
      }
      setShowDialogEmp(!showDialogEmp);
    }
  }

  function insertDataByEnter(event: any, type: any) {
    if (!event) {
      return;
    }
    if (type === "to") {
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        to: [...listInToAndPass.to, ...event],
      }));
    } else if (type === "pass") {
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        pass: [...listInToAndPass.pass, ...event],
      }));
    }
  }
  function onSelectedEmployeeToAndPass(data: any) {
    let checkValue: boolean = false;
    const _value = userData.Lang === "EN" ? data.NameEn : data.NameTh;
    if (typeEmployeeDialog === "to") {
      let _components = listInToAndPass;
      _components.to.filter((_item: any) => {
        if (_value === _item) {
          checkValue = true;
        }
      });
      if (checkValue) {
        setDialogVisibleInEmployee(false);
        return;
      }
      _components.to.push(_value);
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        to: [..._components.to],
      }));
    } else if (typeEmployeeDialog === "pass") {
      let _components = listInToAndPass;
      _components.pass.filter((_item: any) => {
        if (_value === _item) {
          checkValue = true;
        }
      });
      if (checkValue) {
        setDialogVisibleInEmployee(false);
        return;
      }
      _components.pass.push(_value);
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        pass: [..._components.pass],
      }));
    }
    setDialogVisibleInEmployee(false);
  }
  function selecteOrTypeListInToAndPass(event: any, type: any) {
    const _value = event.value.map((item: any) => {
      if (!(typeof item === "string")) {
        if (userData.Lang === "TH") {
          return item.NameTh;
        } else {
          return item.NameEn;
        }
      } else {
        return item;
      }
    });

    if (type === "to") {
      let _components = listInToAndPass.to;
      _components = [..._value];
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        to: [..._components],
      }));
    } else if (type === "pass") {
      let _components = listInToAndPass.pass;
      _components = [..._value];
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        pass: [..._components],
      }));
    }
  }

  async function openWindow(link: any) {
    var pathArray = link?.split("/");
    const _link = link
      ? link.charAt(0) !== "/" && !_sharepointSiteURL
        ? "/" + link
        : link
      : "";

    const protocol = window.location.protocol;
    if (_sharepointSiteURL) {
      if (_link.startsWith(_sharepointSiteURL)) {
        window.open(`${_link}`, "_blank", "noreferrer");
      } else {
        window.open(`${_sharepointSiteURL}${_link}`, "_blank", "noreferrer");
      }
    } else if (_userData.TinyURL) {
      const _port = location.port;
      if (location.host !== "localhost:5001") {
        if (_port) {
          _userData.TinyURL = _userData.TinyURL + ":" + _port;
        }
      }
      if (
        _link.includes("www") ||
        _link.includes(".com") ||
        _link.includes(".tv") ||
        _link.includes(".net") ||
        _link.includes("https") ||
        _link.includes("http") ||
        _link.includes(".com") ||
        _link.includes(".co.th")
      ) {
        if (_link.includes("https")) {
          window.open(
            `https://${_link.replaceAll("https://", "")}`,
            "_blank",
            "noreferrer"
          );
        } else if (_link.includes("http")) {
          window.open(
            `http://${_link.replaceAll("http://", "")}`,
            "_blank",
            "noreferrer"
          );
        } else {
          window.open(`https://${_link}`, "_blank", "noreferrer");
        }
      } else {
        window.open(
          `${protocol}//${_userData.TinyURL}${_link}`,
          "_blank",
          "noreferrer"
        );
      }
    } else if (!_userData.TinyURL) {
      window.open(`${_link}`, "_blank", "noreferrer");
    }
  }

  const fetchMasterData = async () => {
    const masterDataList = await GetAllMasterData();
    let masterData: any[] = [];
    let userManual: any[] = [];
    if (masterDataList) {
      const masterDataType = masterDataList.filter((e: any) => {
        return e.MasterType === "UserManual" && e.IsActive === true;
      });
      masterDataType.map((masterlist: any) => {
        const userManualMaster = masterlist.Value1.split("|");
        let master = userManualMaster.find((e: any) => {
          let masterDataList = e === memoDetail.template_code;
          return masterDataList;
        });
        masterData.push(master);
      });
    }
    // const userManualList = await GetAllDynamic("UserManual/GetAll", undefined);
    // if (userManualList) {
    //   const manual = userManualList.find((e: any) => {
    //     let manualList = e.UserManualGroupName === memoDetail.template_code;
    //     return manualList;
    //   });
    //   userManual.push(manual);
    // }

    if (masterData[0] && userManual[0]) {
      if (masterData[0] === userManual[0].UserManualGroupName) {
        setHasUserManual(true);
        const [nameFileSplit, linkFileSplit] =
          userManual[0].AttachFile.split("|");
        setNameFile(nameFileSplit);
        setLinkFile(linkFileSplit);
      } else {
        setHasUserManual(false);
      }
    }
  };
  const customModalStyle = {
    padding: "30px",
    maxHeight: "20px",
  };

  const columnsTo: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (_, record: any, idx: number) => {
        return (
          <>
            <div>{idx + 1}</div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: any) => {
        return (
          <>
            <div>{record}</div>
          </>
        );
      },
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>Action</div>
      ),
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            icon="pi pi-trash"
            className="set-button-css-ref"
            onClick={() => {
              filterToCC(record, "to");
            }}
          />
        </div>
      ),
    },
  ];

  const columnsCC: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (_, record: any, idx: number) => {
        return (
          <>
            <div>{idx + 1}</div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: any) => {
        return (
          <>
            <div>{record}</div>
          </>
        );
      },
    },

    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>Action</div>
      ),
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon="pi pi-trash"
            className="set-button-css-ref"
            onClick={() => {
              filterToCC(record, "pass");
            }}
          />
        </Space>
      ),
    },
  ];

  // useEffect(() => {
  //   showMore("to");
  // }, [listInToAndPass.to]);

  const filterToCC = (event: any, type: any) => {
    if (type === "to") {
      const filtered = listInToAndPass.to.filter((e: any) => e !== event);
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        to: [...filtered],
      }));

      Modal.destroyAll();
    } else if (type === "pass") {
      const filtered = listInToAndPass.pass.filter((e: any) => e !== event);
      setListInToAndPass((prevState: any) => ({
        ...prevState,
        pass: [...filtered],
      }));

      Modal.destroyAll();
    }
  };

  const showMore = (type: any) => {
    Modal.info({
      title: (
        <>
          {type === "to" ? (
            <>
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                To(เรียนถึง) ทั้งหมด
              </p>
            </>
          ) : (
            <>
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                CC(สำเนา) ทั้งหมด
              </p>
            </>
          )}
        </>
      ),
      content: (
        <div>
          <>
            {" "}
            <Table
              columns={type === "to" ? columnsTo : columnsCC}
              dataSource={
                type === "to" ? listInToAndPass.to : listInToAndPass.pass
              }
            />
          </>
        </div>
      ),
      style: customModalStyle,
      closable: true,
    });
  };
  const [getMasteLeave, setMasteLeave] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (memoDetail && memoDetail.template_code) {
        // ตรวจสอบว่ามีค่า template_code
        const MasteLeave = await GetMasteLeave(memoDetail.template_code);
        setMasteLeave(MasteLeave);
      }
    }

    fetchData();
  }, [memoDetail?.template_code]);
  return (
    <div>
      <Row className="set-layout-in-row-respone">
        <Col sm={6} xs={12} style={{ alignItems: "center" }}>
          <div className="badge-display-justify-content">
            <div className="Col-text-header-Inform">{t("information")}</div>
          </div>
          <p className="txt-template-name-custom-style">
            {memoDetail?.status === "New Request" &&
              memoDetail.template_code + ": " + memoDetail.template_name}
            {memoDetail?.status !== "New Request" && memoDetail.template_name}
          </p>
          {hasUserManual === true ? (
            <>
              <Row className=" ">
                <Col xs={4} sm={4} xl={4}>
                  {" "}
                  <TextHeaderComponents
                    textHeaderProps={"User Manual :"}
                    textSubProps={"คู๋มือการใช้งาน :"}
                  />
                </Col>
                <Col xs={8} sm={8} xl={8}>
                  <Button
                    label={nameFile}
                    onClick={() => {
                      openWindow(linkFile);
                    }}
                    className="r"
                    type="button"
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "rgb(40, 47, 106)",
                      border: "1px solid rgb(40, 47, 106)",
                      boxShadow: "none",
                      width: "100%",
                      height: "38px",
                      zIndex: "1",
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Col>
        <Col sm={6} xs={12} className="set-css-display-flex-status">
          <Badge
            value={t(memoDetail?.status)}
            className="set-css-status-new"
            style={{
              color: "#FFFFFF",
              background:
                memoDetail?.status === "Wait for Approve"
                  ? "#282f6a"
                  : memoDetail?.status === "Recall"
                  ? "#F8A51C"
                  : memoDetail?.status === "Draft"
                  ? "#B4B4B4"
                  : memoDetail?.status === "Completed"
                  ? "#28a745"
                  : memoDetail?.status === "New Request"
                  ? "#2769B2"
                  : memoDetail?.status === "Rework"
                  ? "#F8A51C"
                  : memoDetail?.status === "Cancelled"
                  ? "#dc3545"
                  : memoDetail?.status === "Rejected"
                  ? "#dc3545"
                  : memoDetail?.status === "Wait for Comment"
                  ? "#06BEE1"
                  : "",
            }}
          ></Badge>
          <p className="set-css-doc-code-respone">{memoDetail?.document_no}</p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <p className="Col-text-header"></p>
        </Col>
      </Row>
      <div className="Information-panding-card">
        <Row className=" ">
          <Col xs={4} sm={2} xl={2}>
            <tr>
              <th>
                <p
                  id="lbl_Info_RequestDate_EN"
                  className="information-text-header-p"
                >
                  Request Date
                </p>
                <p
                  id="lbl_Info_RequestDate_TH"
                  className="information-text-sub-p"
                >
                  วันที่ร้องขอเอกสาร
                </p>
              </th>
            </tr>
          </Col>

          <Col xs={8} sm={10} xl={6}>
            <div
              className="information-inputTexta-width"
              style={{ color: "#b4b4b4", fontSize: "13px" }}
            >
              {formatDateTime(
                memoDetail?.request_date,
                masterSetting?.FormatDate?.Value1?.replaceAll("d", "D")
              )}
            </div>
          </Col>
        </Row>
        <Row className=" ">
          <Col xs={12} sm={12} xl={2}>
            <tr>
              <th>
                <div className="label-text-container">
                  <span
                    id="lbl_Info_Company_EN"
                    className="information-text-header-p"
                  >
                    Company
                  </span>
                  <span className="headtext-form set-text-information-require">
                    {" "}
                    *
                  </span>
                </div>
                <p id="lbl_Info_Company_TH" className="information-text-sub-p">
                  บริษัท
                </p>
              </th>
            </tr>
          </Col>

          <Col xs={12} sm={12} xl={4}>
            <div
              className="p-inputgroup"
              style={{
                height: "38px",
                width: "100% ",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <InputTextComponents
                setClassNameProps="information-inputTexta-width-inputText-company"
                setStyleDivProps={{ flex: "1" }}
                valueProps={memoDetail?.company_name}
                placeholderProps={"Select"}
                disabledProps={!canEditDoc}
                setStyleProps={{
                  height: "38px",
                  width: "100% ",
                  fontSize: "13px",
                }}
                onClickProps={showModalCompany}
                readOnlyProps={true}
              />
              <ButtonComponents
                setIconProps={"pi pi-search"}
                setClassNameProps={"p-button-text-position"}
                onClickProps={showModalCompany}
                setStyleProps={{
                  backgroundColor: "#282f6a",
                  border: "1px solid #282f6a",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  boxShadow: "none",
                  height: "38px",
                }}
                disabledProps={!canEditDoc}
              />
            </div>
          </Col>
          {!hideBranch && (
            <>
              <Col xs={12} sm={12} xl={2}>
                <tr>
                  <th>
                    <p
                      id="lbl_Info_Branch_EN"
                      className="information-text-header-p"
                    >
                      Branch
                    </p>
                    <p
                      id="lbl_Info_Branch_TH"
                      className="information-text-sub-p"
                    >
                      สาขา
                    </p>
                  </th>
                </tr>
              </Col>

              <Col xs={12} sm={12} xl={4}>
                <InputTextComponents
                  setStyleProps={{ height: 38 }}
                  setClassNameProps={"information-inputTexta-width"}
                  placeholderProps={""}
                  disabledProps={true}
                  valueProps={
                    isBranchFromADTitle && memoDetail?.requestor?.ADTitle
                      ? memoDetail?.requestor?.ADTitle
                      : ""
                  }
                />
              </Col>
            </>
          )}
        </Row>

        <Row className="">
          {!hideTo && (
            <>
              <Col xs={12} xl={2}>
                <tr>
                  <th>
                    <p
                      id="lbl_Info_To_EN"
                      className="information-text-header-p"
                    >
                      To
                    </p>
                    <p id="lbl_Info_To_TH" className="information-text-sub-p">
                      เรียน
                    </p>
                  </th>
                </tr>
              </Col>

              <Col xs={12} xl={10}>
                <div
                  className="p-inputgroup "
                  style={{
                    height: `${
                      listInToAndPass.to?.length > 0 ? "100%" : "38px"
                    }`,
                  }}
                >
                  <AutoComplete
                    value={isMobile ? toValue : listInToAndPass.to}
                    suggestions={filteredEmployee}
                    completeMethod={searchEmployeeToAndPass}
                    field={"NameTh"}
                    className="information-autoComplete"
                    multiple
                    panelClassName="information-autoComplete-panel"
                    disabled={
                      !canEditDoc ||
                      (isMobile && listInToAndPass.to?.length > 3)
                        ? true
                        : false
                    }
                    onKeyPress={(e: any) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter") {
                        let auto = e.target.value.split(",");
                        insertDataByEnter(auto, "to");
                        e.target.value = "";
                      }
                    }}
                    onChange={(e) => {
                      selecteOrTypeListInToAndPass(e, "to");
                    }}
                  />
                  {isMobile && listInToAndPass.to.length > 3 && (
                    <button
                      className="show-more-btn"
                      onClick={() => {
                        showMore("to");
                      }}
                    >
                      More({listInToAndPass.to.length - 3})
                    </button>
                  )}
                  <ButtonComponents
                    setIconProps={"pi pi-search"}
                    setClassNameProps={"p-button-text-position"}
                    onClickProps={() => showModalInEmployeeToAndPass("to")}
                    setStyleProps={{
                      backgroundColor: "#282f6a",
                      border: "1px solid #282f6a",
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                      boxShadow: "none",
                      height: "100%",
                    }}
                    disabledProps={!canEditDoc}
                  />
                </div>
              </Col>
            </>
          )}
        </Row>

        <Row className="">
          {!hideCc && (
            <>
              <Col xs={12} xl={2}>
                <tr>
                  <th>
                    <p
                      id="lbl_Info_CC_EN"
                      className="information-text-header-p"
                    >
                      Cc
                    </p>
                    <p id="lbl_Info_CC_TH" className="information-text-sub-p">
                      สำเนา
                    </p>
                  </th>
                </tr>
              </Col>
              <Col xs={12} xl={10}>
                <div
                  className="p-inputgroup "
                  style={{
                    height: `${
                      listInToAndPass.pass?.length > 0 ? "100%" : "38px"
                    }`,
                  }}
                >
                  <AutoComplete
                    value={isMobile ? CCValue : listInToAndPass.pass}
                    suggestions={filteredEmployee}
                    completeMethod={searchEmployeeToAndPass}
                    field={userData.Lang === "TH" ? "NameTh" : "NameEn"}
                    className="information-autoComplete"
                    multiple
                    panelClassName="information-autoComplete-panel"
                    disabled={
                      !canEditDoc ||
                      (isMobile && listInToAndPass.pass?.length > 3)
                        ? true
                        : false
                    }
                    onKeyPress={(e: any) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter") {
                        let auto2 = e.target.value.split(",");
                        insertDataByEnter(auto2, "pass");
                        e.target.value = "";
                      }
                    }}
                    onChange={(e) => {
                      selecteOrTypeListInToAndPass(e, "pass");
                    }}
                  />
                  {isMobile && listInToAndPass.pass.length > 3 && (
                    <button
                      className="show-more-btn"
                      onClick={() => {
                        showMore("pass");
                      }}
                    >
                      More({listInToAndPass.pass.length - 3})
                    </button>
                  )}
                  <ButtonComponents
                    setIconProps={"pi pi-search"}
                    setClassNameProps={"p-button-text-position"}
                    onClickProps={() => showModalInEmployeeToAndPass("pass")}
                    setStyleProps={{
                      backgroundColor: "#282f6a",
                      border: "1px solid #282f6a",
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                      boxShadow: "none",
                      height: "100%",
                    }}
                    disabledProps={!canEditDoc}
                  />
                </div>
              </Col>
            </>
          )}
        </Row>
        {!hideProject && (
          <Row className=" ">
            <Col xs={12} sm={12} xl={2}>
              <tr>
                <th>
                  <p className="information-text-header-p">Project</p>
                  <p className="information-text-sub-p">โครงการ</p>
                </th>
              </tr>
            </Col>
            <Col xs={12} sm={12} xl={10}>
              <Dropdown
                options={masterProjects}
                value={project}
                onChange={(event: any) => {
                  setProject(event.value);
                }}
                filter
                virtualScrollerOptions={{
                  itemSize: 38,
                }}
                panelStyle={{ fontSize: "13px" }}
                optionLabel="ProjectNameWithCode"
                placeholder={"Select Project"}
                className="information-inputTexta-width-Button-Company"
                style={{
                  borderRadius: "6px",
                  height: 38,
                  fontSize: "13px",
                }}
                disabled={!canEditDoc}
              />
            </Col>
          </Row>
        )}
        <Row className=" ">
          {!hideSubject && (
            <>
              <Col xs={12} sm={12} xl={2}>
                <tr>
                  <th>
                    <div className="label-text-container">
                      <span
                        id="lbl_Info_Subject_EN"
                        className="information-text-header-p"
                      >
                        Subject
                      </span>
                      <span className="headtext-form set-text-information-require">
                        {" "}
                        *
                      </span>
                    </div>
                    <p
                      id="lbl_Info_Subject_TH"
                      className="information-text-sub-p"
                    >
                      เรื่อง
                    </p>
                  </th>
                </tr>
              </Col>
              <Col xs={12} sm={12} xl={10}>
                <InputTextComponents
                  setClassNameProps="information-inputTexta-width"
                  setStyleProps={{ height: 38 }}
                  onBlurProps={onBlurProps}
                  onFocusProps={onFocusProps}
                  onChangeProps={(event: any) => {
                    setMemoDetail((prevState: any) => ({
                      ...prevState,
                      subject: event,
                    }));
                  }}
                  valueProps={memoDetail.subject}
                  disabledProps={!canEditDoc}
                />
              </Col>
            </>
          )}
        </Row>
        {refAttribute?.mode && refAttribute?.position === "Top" && (
          <>
            {canEditRefDoc ? (
              <>
                <Row>
                  <Col xs={12} sm={12} xl={2}>
                    <tr>
                      <th>
                        <div className="label-text-container">
                          <p className="information-text-header-p">
                            Reference Template
                          </p>
                          {/* <span className="headtext-form text-Is-require">*</span> */}
                        </div>
                        <p className="information-text-sub-p">เอกสารอ้างอิง</p>
                      </th>
                    </tr>
                  </Col>
                  <Col xs={12} sm={12} xl={10}>
                    <ButtonComponents
                      setIconProps={"pi pi-plus"}
                      setClassNameProps={"p-button-text-position"}
                      onClickProps={() => {
                        setDialogVisibleInRefTemplate(true);
                      }}
                      setLabelProps={"Add"}
                      loading={refLoading}
                      setStyleProps={{
                        backgroundColor: "#282f6a",
                        border: "1px solid #282f6a",
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: "6px",
                        boxShadow: "none",
                        height: "38px",
                      }}
                      disabledProps={!canEditDoc}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} xl={2}></Col>
                  <Col xs={12} sm={12} xl={10}>
                    <TableReferenceComponent
                      canEditDoc={canEditDoc}
                      refLoading={refLoading}
                      refAttribute={refAttribute}
                      refTempSelected={refTempSelected}
                      setRefTempSelected={setRefTempSelected}
                      status={memoDetail?.status}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col xs={12} sm={12} xl={2}>
                    <tr>
                      <th>
                        <div className="label-text-container">
                          <p className="information-text-header-p">
                            Reference Template
                          </p>
                          {/* <span className="headtext-form text-Is-require">*</span> */}
                        </div>
                        <p className="information-text-sub-p">เอกสารอ้างอิง</p>
                      </th>
                    </tr>
                  </Col>
                  <Col xs={12} sm={12} xl={10}>
                    <TableReferenceComponent
                      canEditDoc={canEditDoc}
                      refLoading={refLoading}
                      refAttribute={refAttribute}
                      refTempSelected={refTempSelected}
                      setRefTempSelected={setRefTempSelected}
                      status={memoDetail?.status}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        <Row>
          {checkedLeaveTemplate && (
            <LeaveTypeComponents
              leaveTypeTable={leaveTypeTable}
              LeaveConfig={getMasteLeave}
            />
          )}
        </Row>
        {isTemplateVersion && (
          <TemplateVersionComponent
            status={memoDetail?.status}
            setSelectedTemplateVersion={setSelectedTemplateVersion}
            selectedTemplateVersion={selectedTemplateVersion}
            templateListVersion={templateListVersion}
            canEditDoc={canEditDoc}
          />
        )}
        <Row></Row>
        <Row>
          {!isTextFromValue && (
            <CoreControllerComponentTest
              getValues={getValues}
              refTempSelected={refTempSelected}
              listFormNames={listFormNames}
              setListRefDocDetails={setListRefDocDetails}
              refAttribute={refAttribute}
              numFormulas={numFormulas}
              setLineApproval={setLineApproval}
              setListFileAttachDetails={setListFileAttachDetails}
              returnMessage={returnMessage}
              listReturn={listReturn}
              {...{
                trigger,
                tableOptions,
                setTableOptions,
                onProcessLogicReference,
                prepareInitialLogic,
                isFirstRun,
                previousView,
                previousRefTempSelected,
                buttonType,
                control,
                register,
                handleSubmit,
                onSubmit,
                onSubmitInterface,
                errors,
                onControlChange,
                allLogic,
                reset,
                memoDetail,
                jsonConditions,
                setJsonConditions,
                setValue,
                canEditDoc,
                checkActionPage,
                isControlLoading,
                setIsControlLoading,
                tableSummaries,
                setTableSummaries,
              }}
              setButtonType={setButtonType}
              templateDesc={memoDetail?.template_desc}
              setMemoDetail={setMemoDetail}
              documentNo={memoDetail.document_no} // control={control}
              autoNumFormat={autoNumFormat}
              lineApproval={lineApproval}
              getLineApproveForAmount={getLineApproveForAmount}
              checkInterFace={checkInterFace}
              interfaceButton={interfaceButton}
              actionAlert={actionAlert}
              edFont={edFont}
              defaultFont={defaultFont}
            />
          )}
          {isTextFromValue && (
            <TinyMceComponent
              setTextFromValue={setTextFromValue}
              textFromValue={textFromValue}
              canEditDoc={canEditDoc}
            />
          )}
        </Row>
        {isShowSum && (
          <Row>
            <Col></Col>
            <Col>
              <Row>
                <Col
                  xl={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <p>All Total</p>
                </Col>
                <Col xl={4}>
                  <Input
                    value={memoDetail.amount}
                    onChange={(e) => {
                      setMemoDetail((prevState: any) => ({
                        ...prevState,
                        amount: e.target.value,
                      }));
                    }}
                    onBlur={(e) => {
                      getLineApproveForAmount(memoDetail.amount);
                    }}
                    disabled={!listFormNames.IsTextForm}
                    id="AllTotal"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      {refAttribute?.mode && refAttribute?.position === "Bottom" && (
        <>
          {canEditDoc ? (
            <>
              <Row>
                <Col xs={12} sm={12} xl={2}>
                  <tr>
                    <th>
                      <div className="label-text-container">
                        <p className="information-text-header-p">
                          Reference Template
                        </p>
                        {/* <span className="headtext-form text-Is-require">*</span> */}
                      </div>
                      <p className="information-text-sub-p">เอกสารอ้างอิง</p>
                    </th>
                  </tr>
                </Col>
                <Col xs={12} sm={12} xl={10}>
                  <ButtonComponents
                    setIconProps={"pi pi-plus"}
                    setClassNameProps={"p-button-text-position"}
                    onClickProps={() => {
                      setDialogVisibleInRefTemplate(true);
                    }}
                    loading={refLoading}
                    setLabelProps={"Add"}
                    setStyleProps={{
                      backgroundColor: "#282f6a",
                      border: "1px solid #282f6a",
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                      boxShadow: "none",
                      height: "38px",
                    }}
                    disabledProps={!canEditDoc}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} xl={2}></Col>
                <Col xs={12} sm={12} xl={10}>
                  <TableReferenceComponent
                    canEditDoc={canEditDoc}
                    refLoading={refLoading}
                    refAttribute={refAttribute}
                    refTempSelected={refTempSelected}
                    setRefTempSelected={setRefTempSelected}
                    status={memoDetail?.status}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col xs={12} sm={12} xl={2}>
                  <tr>
                    <th>
                      <div className="label-text-container">
                        <p className="information-text-header-p">
                          Reference Template
                        </p>
                        {/* <span className="headtext-form text-Is-require">*</span> */}
                      </div>
                      <p className="information-text-sub-p">เอกสารอ้างอิง</p>
                    </th>
                  </tr>
                </Col>
                <Col xs={12} sm={12} xl={10}>
                  <TableReferenceComponent
                    canEditDoc={canEditDoc}
                    refLoading={refLoading}
                    refAttribute={refAttribute}
                    refTempSelected={refTempSelected}
                    setRefTempSelected={setRefTempSelected}
                    status={memoDetail?.status}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      <>
        <SelectDataDialog
          dialogKey={"Company"}
          dataList={masterCompanies}
          onSelectFunc={onSelectedCompany}
          columns={[
            {
              field: "CompanyCode",
              headerEn: "CompanyCode",
              headerTh: "รหัสบริษัท",
            },
            {
              field: "NameEn",
              headerEn: "Name",
              headerTh: "ชื่อ",
            },
            {
              field: "AddressEn",
              headerEn: "Address",
              headerTh: "ที่อยู่",
            },
          ]}
          dialogVisible={dialogVisibleInCompany}
          setDialogVisible={setDialogVisibleInCompany}
        />
        <SelectDataDialog
          dialogKey={"Employee"}
          dataList={employeeList}
          onSelectFunc={onSelectedEmployeeToAndPass}
          mutiable
          columns={[
            {
              field: "EmployeeCode",
              headerEn: "EmployeeCode",
              headerTh: "รหัสพนักงาน",
            },
            {
              field: "NameEn",
              headerEn: "Name",
              headerTh: "ชื่อ",
            },
            {
              field: "PositionNameEn",
              headerEn: "Position",
              headerTh: "ตำแหน่ง",
            },
            {
              field: "DepartmentNameEn",
              headerEn: "Department",
              headerTh: "หน่วยงาน",
            },
          ]}
          dialogVisible={dialogVisibleInEmployee}
          setDialogVisible={setDialogVisibleInEmployee}
          loading={showDialogEmp}
        />
        {refAttribute?.mode && (
          <DialogRefTemplateComponent
            refAttribute={refAttribute}
            dialogVisibleInRefTemplate={dialogVisibleInRefTemplate}
            setDialogVisibleInRefTemplate={setDialogVisibleInRefTemplate}
            refTempSelected={refTempSelected}
            refObtions={refOptions}
            setRefTempSelected={setRefTempSelected}
            setSearchRefDocData={setSearchRefDocData}
            searchRefDocData={searchRefDocData}
            previousRefTempSelected={previousRefTempSelected}
            memoDetail={memoDetail}
            setIsControlLoading={setIsControlLoading}
          />
        )}
      </>
    </div>
  );
};

export default InformationComponentFix;
