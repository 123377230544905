import moment from "moment";
import {
  IAutoNumberAttibute,
  IAutoNumberFormat,
} from "../IRequestModel/IAutoNumberFormat";
import { IMemoDetailModel } from "../IRequestModel/IMemoDetailModel";
import {
  GetAutoNumber,
  onDatareladToLoadData,
} from "../Services/RequestControlService";
import { IUserModel } from "../IRequestModel/IUserModel";
import _ from "lodash";
export async function genAutoNum(
  _control: any,
  autoNumFormat: IAutoNumberAttibute,
  template_id: any,
  status: any,
  isCopy: boolean = false
) {
  try {
    if (autoNumFormat.formats.length > 0) {
      let autoNumberAttibute: IAutoNumberAttibute = autoNumFormat;
      let str: string[] = [];
      let isCheck: boolean = false;
      let requestBody: any = {};
      let formats: IAutoNumberFormat[] = autoNumberAttibute.formats;
      let choiceFormat = null;
      let tempLabels: string[] = [];
      let tempValues: string[] = [];
      
      for (const format of formats) {
        if (format.condition.length > 0) {
          const conditions = format.condition;

          // Track if all conditions are satisfied
          const allConditionsMatched = conditions.every((condition: any, idx: number) => {
            let conditionMatched = false; // Track if the current condition is matched

            _control.forEach((item: any) => {
              item.layout.forEach((layout: any) => {
                if (condition.label === layout.template?.label) {
                  let tempValue = layout?.data?.value;

                  if (!tempValue && layout.template?.type === "dd") {
                    tempValue = getDefValueInTemplate(layout.template);
                  }

                  if (_.isEqual(condition.value?.trim(), tempValue?.trim())) {
                    conditionMatched = true;
                  } else {
                    console.log("miss", condition.value?.trim(), tempValue?.trim());
                  }

                }
              });
            });
            console.log(conditionMatched, condition.value, "tempValue");

            return conditionMatched;
          });
          console.log(allConditionsMatched, conditions, "allConditionsMatched ");

          if (allConditionsMatched) {
            choiceFormat = format
            for (const condition of conditions) {
              if (!tempLabels.includes(condition.label)) {
                tempLabels.push(condition.label);
                tempValues.push(condition.value);
              }
            }
            break;
          }
        }
      }

      if(!choiceFormat){
        choiceFormat = formats.find((format) => format.condition.length ===0) ??null
      }

      if (choiceFormat !== null) {
        choiceFormat.format.map((format: any) => {
          if (format.type) {
            if (format.type === "pf") {
              if (!str.includes(format.label)) {
                str.push(format.label);
              }
            } else if (format.type === "datetime") {
              const formattedYear = moment().format(format.label);
              str.push(formattedYear);
            } else if (format.type === "ddl") {
              _control.forEach((item: any) => {
                item.layout.forEach(async (layout: any) => {
                  if (layout.template.label === format.label) {
                    let value: string = layout.data.value;
                    let newPosition = format.position;

                    if (value !== null) {
                      if (format.condition === "Split") {
                        let newFormat = value.split(format.symbol);
                        for (let i = 0; i < newFormat.length; i++) {
                          newPosition = newPosition.replace(
                            new RegExp("\\$\\{" + (i + 1) + "\\}", "gi"),
                            newFormat[i]
                          );
                        }
                        if (
                          newPosition.indexOf("(") > -1 &&
                          newPosition.indexOf(")")
                        ) {
                          str.push(
                            newPosition.substring(
                              newPosition.indexOf("(") + 1,
                              newPosition.indexOf(")")
                            )
                          );
                        } else {
                          str.push(newPosition);
                        }
                      } else {
                        if (value?.indexOf("(") > -1 && value?.indexOf(")")) {
                          str.push(
                            value.substring(
                              value.indexOf("(") + 1,
                              value.indexOf(")")
                            )
                          );
                        } else {
                          str.push(value);
                        }
                      }
                    } else {
                      str.push(getDefValueInTemplate(layout.template));
                    }
                  }
                });
              });
            }
          }
        });

        if (str.length === choiceFormat.format.length) {
          if (
            !str.includes("--select--") &&
            !str.includes("-- select --") &&
            !str.includes("-- please select --") &&
            !str.includes("--- please select ---") &&
            !str.includes("--please select--") &&
            !str.includes("---please select---") &&
            !str.includes("--Select--") &&
            !str.includes("-- Select --") &&
            !str.includes("-- Please Select --") &&
            !str.includes("--- Please Select ---") &&
            !str.includes("--Please Select--") &&
            !str.includes("---Please Select---")
          ) {
            isCheck = true;
          }
        }
      }
      
      if (
        _control[autoNumberAttibute.rowIndex].layout[
          autoNumberAttibute.colIndex
        ].isShow
      ) {
        if (status === "New Request" || isCopy) {
          if (isCheck) {
            const showSymbol = autoNumFormat?.showSymbol ? "-" : "-";

            let prefix = "";
          
            prefix = typeof(showSymbol)=== "string" ? str.join(showSymbol) + showSymbol :""
            autoNumFormat.fisrtPreix = prefix;
            requestBody.Prefix = prefix;
            requestBody.Digit = autoNumFormat.digit;
            requestBody.TemplateId = template_id;
            requestBody.Status = status;
            requestBody.Label =
              _control[autoNumberAttibute.rowIndex].layout[
                autoNumberAttibute.colIndex
              ].template.label;
            const dd = await GetAutoNumber(requestBody);
            if (dd.Message !== undefined) {
            } else {
              _control[autoNumberAttibute.rowIndex].layout[
                autoNumberAttibute.colIndex
              ].data.value = dd;
            }
          } else if (!isCheck) {
            _control[autoNumberAttibute.rowIndex].layout[
              autoNumberAttibute.colIndex
            ].data.value = "";
          }
        }
      } else {
        _control[autoNumberAttibute.rowIndex].layout[
          autoNumberAttibute.colIndex
        ].data.value = "";
      }
    }
  } catch (error) {
    console.error("auto=>error", error);
  }
  return _control;
}

export const callGenAutoNumber = (
  autoNumFormat: IAutoNumberAttibute,
  controlTemplate: any
) => {
  let callAutoNumber = false;
  try {
    for (let i = 0; i < autoNumFormat.formats.length; i++) {
      if (autoNumFormat.formats[i].format.length > 0) {
        const format = autoNumFormat.formats[i].format;
        callAutoNumber =
          format.find((e) => e.label === controlTemplate.label) !== undefined;
        if (callAutoNumber) {
          break;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return callAutoNumber;
};

export function getValueControl(
  controlID: string,
  memoDetail: IMemoDetailModel
) {
  let strValue: string = "";

  switch (controlID.toLowerCase()) {
    case "requestor id":
      strValue = memoDetail.requestor.EmployeeId.toString();
      break;
    case "requestor code":
      strValue = memoDetail.requestor.EmployeeCode;
      break;
    case "requestor name":
      strValue = memoDetail.requestor.NameEn;
      break;
    case "requestor email":
      strValue = memoDetail.requestor.Email;
      break;
    case "requestor position":
      strValue = memoDetail.requestor.PositionNameEn;
      break;
    case "requestor department":
      strValue = memoDetail.requestor.DepartmentNameEn;
      break;
    default:
      // handle default case here
      break;
  }

  return strValue;
}

export const getDefValueInTemplate = (template: any) => {
  const templateType = template?.type;
  let returnValue: any = null;
  if (templateType === "t" || templateType === "ta" || templateType === "c") {
    returnValue = template.attribute.default;
  } else if (templateType === "dd" || templateType === "r") {
    if (template.attribute.items && template.attribute.items.length > 0) {
      const selectedItem = template.attribute.items.find(
        (e: any) => e.checked === "Y"
      );

      returnValue = selectedItem?.item || template.attribute.items[0].item;
      if (templateType === "r") {
        returnValue = selectedItem?.item || "";
      }
    } else {
      returnValue = "-- Please Select --";
    }
    // DefaultCalendar
  } else if (templateType === "cb") {
    if (template.attribute.items && template.attribute.items.length > 0) {
      const selectedItem = template.attribute.items.filter(
        (e: any) => e.check === "Y"
      );

      returnValue = selectedItem.map((e: any) => e.item).join(",");
    }
  } else if (templateType === "d") {
    if (template.attribute.defaultType === "Days") {
      let defaultDate = parseInt(template.attribute.default);
      let defValue = moment(Date.now())
        .add(defaultDate, "days")
        .format("DD MMM YYYY");
      returnValue = defValue;
    }
  }

  return returnValue;
};

export function getRefInforValue(
  controlID: string,
  memoDetail: IMemoDetailModel,
  userData: IUserModel,
  defValue: string = ""
) {
  let strValue: string = defValue;
  if (memoDetail) {
    switch (controlID) {
      case "Requestor_Employee_code":
        strValue = memoDetail.requestor?.EmployeeCode;
        break;
      case "Requestor_NameEn":
        strValue = memoDetail.requestor?.NameEn;
        break;
      case "Requestor_NameTh":
        strValue = memoDetail.requestor?.NameTh;
        break;
      case "Requestor_PositionEn":
        strValue = memoDetail.requestor?.PositionNameEn;
        break;
      case "Requestor_PositonEn":
        strValue = memoDetail.requestor?.PositionNameEn;
        break;
      case "Requestor_PositionTh":
        strValue = memoDetail.requestor?.PositionNameTh;
        break;
      case "Requestor_PositonTh":
        strValue = memoDetail.requestor?.PositionNameTh;
        break;
      case "Requestor_DivisionEn":
        strValue = memoDetail.requestor?.DivisionNameEn;
        break;
      case "Requestor_DivisionTh":
        strValue = memoDetail.requestor?.DivisionNameTh;
        break;
      case "Requestor_DepartmentEn":
        strValue = memoDetail.requestor?.DepartmentNameEn;
        break;
      case "Requestor_DepartmentTh":
        strValue = memoDetail.requestor?.DepartmentNameTh;
        break;
      case "Requestor_Email":
        strValue = memoDetail.requestor?.Email;
        break;
      case "Actor_Employee_code":
        strValue = userData?.EmployeeCode;
        break;
      case "Actor_NameEn":
        strValue = userData?.NameEn;
        break;
      case "Actor_NameTh":
        strValue = userData?.NameTh;
        break;
      case "Actor_PositionEn":
        strValue = userData?.PositionNameEn;
        break;
      case "Actor_PositionTh":
        strValue = userData?.PositionNameTh;
        break;
      case "Actor_PositonEn":
        strValue = userData?.PositionNameEn;
        break;
      case "Actor_PositonTh":
        strValue = userData?.PositionNameTh;
        break;
      case "Actor_DivisionEn":
        strValue = userData?.DivisionNameEn;
        break;
      case "Actor_DivisionTh":
        strValue = userData?.DivisionNameTh;
        break;
      case "Actor_DepartmentEn":
        strValue = userData?.DepartmentNameEn;
        break;
      case "Actor_DepartmentTh":
        strValue = userData?.DepartmentNameTh;
        break;
      case "Actor_Email":
        strValue = userData?.Email;
        break;
      case "Company_Company id":
        strValue = memoDetail?.company_id?.toString();
        break;
      case "Company_Company name":
        strValue = memoDetail.company_id?.toString();
        break;
      default:
        // handle default case here

        break;
    }
  }
  return strValue;
}

export const dataReleteToDD = async (conditions: any[]) => {
  const res = await onDatareladToLoadData(conditions);
  return res;
};
export function balanceParentheses(expression: string) {
  let stack = [];
  let result = [];

  // First pass: Remove unmatched closing parentheses
  for (let char of expression) {
    if (char === "(") {
      stack.push(char);
      result.push(char);
    } else if (char === ")") {
      if (stack.length > 0) {
        stack.pop();
        result.push(char);
      }
    } else {
      result.push(char);
    }
  }

  // Second pass: Remove unmatched opening parentheses
  stack = [];
  let finalResult = [];

  for (let i = result.length - 1; i >= 0; i--) {
    let char = result[i];
    if (char === ")") {
      stack.push(char);
      finalResult.push(char);
    } else if (char === "(") {
      if (stack.length > 0) {
        stack.pop();
        finalResult.push(char);
      }
    } else {
      finalResult.push(char);
    }
  }

  // Reverse to restore the correct order
  return finalResult.reverse().join("");
}

export const calculateAdvanceForm = (form: any) => {
  let tableSums: any = {};

  for (let i = 0; i < form.length; i++) {
    let layouts = form[i];
    for (let j = 0; j < layouts.layout.length; j++) {
      const layout = layouts.layout[j];
      if (layout.template.type === "c") {
        calCulateFunc(layout, tableSums);
      } else if (layout.template.type === "tb") {
        calCulateInTableFunc(layout, tableSums);
      }
    }
  }
  console.log({ form });
};

export const calCulateInTableFunc = (layout: any, tableSums: any) => {
  try {
    const columns: { label: string; attribute: any; type: string }[] =
      layout.template.attribute.column.map((e: any) => {
        return {
          label: e.label,
          attribute: e.control.template.attribute,
          type: e.control.template.type,
        };
      });
    const rowDatas = layout?.data?.row ?? [];
    columns.forEach((col: { label: string; attribute: any; type: string }, colIdx: number) => {
      const decimal = Number(col.attribute.decimal);
      if (col.type === "c") {
        rowDatas.forEach((row: any) => {
          const cell = row[colIdx];
          if (cell) {
            const numericValue = Number(cell.value.toString().replace(/,/g, ""));
            if (!isNaN(numericValue)) {
              cell.value = numericValue.toFixed(decimal);
            }
          }
        });
      }
    });
    if (layout.template.formula === "") return layout?.data?.row;
    const formulas = layout.template.formula.split("|");
    tableSums[layout.template.label] = {};

    rowDatas.forEach((rowData: any, rowIdx: number) => {
      formulas.map((formula: string) => {
        try {
          let resulstField = formula.split("=");
          let formu: string = resulstField[0];
          let resultColIdx = -1;
          let resultDecimal = 0;
          let result: number = 0;

          if (formu.startsWith("Min") || formu.startsWith("Max")) {
            const operator = formu.startsWith("Max") ? "Max" : "Min";
            const fields = formu
              .substring(formu.indexOf("(") + 1, formu.lastIndexOf(")"))
              .split(",");

            fields.forEach((field) => {
              columns.forEach((col: any, colIdx: number) => {
                if (field === col.label) {
                  const value = Number(
                    rowData[col.label]?.toString()?.replaceAll(",", "")
                  );
                  if (!Number.isNaN(value))
                    if (
                      result === 0 ||
                      (operator === "Max" && value > result) ||
                      (operator === "Min" && value < result)
                    ) {
                      result = value;
                    }
                }
                if (col.label === resulstField[1]) {
                  resultColIdx = colIdx;
                  resultDecimal = Number(col.control.attribute.decimal);
                }
              });
            });
          } else if (formu.startsWith("diffdate")) {
            const fields = formu
              .substring(formu.indexOf("(") + 1, formu.lastIndexOf(")"))
              .split(",");

            let firstDate = moment(rowData[fields[0]]);
            let lastDate = moment(rowData[fields[1]]);
            let res = firstDate.diff(lastDate, "days");
            if (res >= 1) {
              res += 1;
            }

            result = res;
            const resCol = columns.find(
              (col: any) => col.label === resulstField[1]
            );
            resultDecimal = Number(resCol?.attribute.decimal);
          } else if (formu.startsWith("difftime")) {
            const fields = formu
              .substring(formu.indexOf("(") + 1, formu.lastIndexOf(")"))
              .split(",");

            let firstDate = moment(rowData[fields[0]], "h.mm");
            let lastDate = moment(rowData[fields[1]], "h.mm");
            let res = firstDate.diff(lastDate, "minutes");

            const hours = Math.trunc(res / 60);
            const remainingMinutes = res % 60;
            let formattedTime =
              res <= -1 && res >= -9
                ? `-0.0${Math.abs(remainingMinutes)}`
                : hours === -0 && res < -10
                ? `-${hours}.${Math.abs(remainingMinutes)}`
                : res < -10
                ? `${hours}.${Math.abs(remainingMinutes)}`
                : `${hours}.${remainingMinutes}`;

            result = Number(formattedTime);
            const resCol = columns.find(
              (col: any) => col.label === resulstField[1]
            );
            resultDecimal = Number(resCol?.attribute.decimal);
          } else {
            const allField =
              formu.match(/(?:get\([^+\-*\/=]+\)|[^+\-*\/=()\[\]{}]+)/g) || [];
            allField.forEach((field: string) => {
              if (field.startsWith("get")) {
                const labels = field
                  .substring(field.indexOf("(") + 1, field.lastIndexOf(")"))
                  .split(",");
                if (labels.length > 0) {
                }
              } else {
                try {
                  columns.forEach(
                    (
                      col: { label: string; attribute: any },
                      colIdx: number
                    ) => {
                      if (field === col.label) {
                        const decimal = Number(col.attribute.decimal);
                        console.log(col);
                        console.log(decimal);

                        const value = Number(
                          rowData[colIdx].value?.toString()?.replaceAll(",", "")
                        ).toFixed(decimal);
                        formu = formu.replace(
                          col.label,
                          value !== "NaN" ? value : Number(0).toFixed(decimal)
                        );
                      }
                      if (col.label === resulstField[1]) {
                        resultColIdx = colIdx;
                        resultDecimal = Number(col.attribute.decimal);
                      }
                    }
                  );
                } catch (error) {
                  console.error(formu + "=>", error);
                }
              }
            });
          }
          let dd = formu.replaceAll(",", "");
          result = eval(dd);
          rowData[resultColIdx].value = result?.toFixed(resultDecimal);


          if (rowDatas) {
            columns.forEach(
              (
                col: { label: string; attribute: any; type: string },
                colIdx: number
              ) => {
                if (col.type === "c") {
                  const decimal = Number(col.attribute.decimal);
                  let colSum: number = 0;
                  rowDatas.forEach((e: any) => {
                    const val = Number(e[colIdx].value);

                    if (!Number.isNaN(val)) {
                      colSum = colSum + val;
                    }
                  });
                  if (tableSums[layout.template.label]) {
                    tableSums[layout.template.label][col.label] = colSum;
                  }
                }
              }
            );
          }
        } catch (error) {
          console.error(formula + "=>", error);
        }
      });
    });
    return rowDatas
  } catch (error) {
    console.error("table=>cal=>error", error);
  }
};

export const calCulateFunc = (layout: any, tableSum: any) => {
  try {
    const formula = layout.template.attribute.formula;
    if (formula === "") return;
    let total: number = NaN;
    if (formula.startsWith("diffdate")) {
      let resulstField = formula.split("=");
      let variables = resulstField[0];
      const selCol = variables
        .substring(variables.indexOf("(") + 1, variables.lastIndexOf(")"))
        .split(",");
      let datepicker1: { [x: string]: moment.Moment } = {
        [selCol[0]]: moment(),
        [selCol[1]]: moment(),
      };

      total = datepicker1[selCol[0]].diff(datepicker1[selCol[1]], "days");
      if (total >= 0) {
        total += 1;
      }
    } else if (formula.startsWith("difftime")) {
      let resulstField = formula.split("=");
      let variables = resulstField[0];
      const selCol = variables
        .substring(variables.indexOf("(") + 1, variables.lastIndexOf(")"))
        .split(",");
      let formattedTime: string = "";

      total = Number(formattedTime);
    } else {
      let resulstField = formula.split("=");

      let match: any = resulstField[0].match(/[^+%\-\*\/=]+/g) || [];

      match.forEach((formu: any) => {
        if (formu?.includes("sum")) {
          try {
            const selCol = formu
              .substring(formu.indexOf("(") + 1, formu.lastIndexOf(")"))
              .split(";");
            
            if (tableSum[selCol[0]]) {
              layout.data.value = tableSum[selCol[0]][selCol[1]] ?? 0;
            }
          } catch (error) {
            console.error("cal=>error", error);
          }
        }
      });
    }
  } catch (error) {
    console.error("table=>cal=>error", error);
  }
};

export const checkRequireFromTemplate = (
  template: any,
  buttonType: string
): boolean => {
  let checked: boolean = false;
  if (
    template.attribute.require === "Y" &&
    ["submit", "approve", "request comment"].includes(buttonType)
  ) {
    checked = true;
  }

  return checked;
};
